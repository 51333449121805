<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col cols="8">
                <div class="title-section">
                    ADMINISTRADOR DE TAREAS
                </div>
            </v-col>
            <v-col cols="4" align="right">
                <div v-for="status in statusOptions" :key="status.value" class="status-text">
                    <v-avatar size="13" :color="status.color"></v-avatar>
                    {{ status.name }}
                </div>
            </v-col>
            <v-col cols="4">
                <v-icon color="black" large>mdi-pin-outline</v-icon>
                <b>Tareas Abiertas</b>
            </v-col>
            <v-col cols="3">
                <v-btn v-if="isAdmin || isAmbUser" class="normal-btn" color="primary" depressed @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Tarea
                </v-btn>
            </v-col>
            <v-col cols="5">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                />
            </v-col>
        </v-row>

        <!-- FILTERS -->
        <v-row align="center">
            <v-col cols="6" md="3">
                <kimsa-select
                    label="Cliente"
                    placeholder="Seleccionar Cliente"
                    :items="clientOptions"
                    outlined solo
                    flat dense
                    item-text="name"
                    item-value="id"
                    force-value
                    :value="filterClient"
                    @change="setFilterClient"
                />
            </v-col>
            <v-col cols="6" md="3">
                <kimsa-select
                    label="Responsable"
                    placeholder="Seleccionar Responsable"
                    :items="userOptions"
                    outlined solo
                    flat dense
                    item-text="name"
                    item-value="id"
                    force-value
                    :value="filterUser"
                    @change="setFilterUser"
                />
            </v-col>
            <v-col cols="6" md="3">
                <kimsa-select
                    label="Status"
                    placeholder="Seleccionar Status"
                    :items="statusOptions"
                    outlined solo
                    flat dense
                    item-text="name"
                    item-value="id"
                    force-value
                    :value="filterStatus"
                    @change="filterStatus = $event"
                />
            </v-col>
            <v-col cols="6" md="3">
                <kimsa-select
                    label="Prioridad"
                    placeholder="Seleccionar Prioridad"
                    :items="priorityOptions"
                    outlined solo
                    flat dense
                    item-text="name"
                    item-value="id"
                    force-value
                    :value="filterPriority"
                    @change="filterPriority = $event"
                />
            </v-col>
            <v-col offset="6" offset-md="9" cols="6" md="3" align="right">
                <v-btn small text @click="clearFilters">
                    Limpiar
                </v-btn>
                <v-btn small color="primary" depressed @click="filterTasks">
                    Buscar
                </v-btn>
            </v-col>
        </v-row>


        <!-- TABLE -->
        <v-data-table
            class="mt-5"
            :headers="headers"
            :loading="loading"
            :search.sync="search"
            :items="tasks"
            :items-per-page="50"
            :footer-props="{'items-per-page-options': [50, 100, 150, 200, -1]}"
            :custom-sort="customSort"
            @click:row="editItem"
        >
            <template v-slot:item.status="{ item }">
                <v-avatar size="20" :color="item.status"/>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="cell-width">
                    <span v-html="item.name"/>
                </div>
            </template>
            <template v-slot:item.client="{ item }">
                <div class="cell-width">
                    <b v-text="item.client"></b>
                </div>
            </template>
            <template v-slot:item.user="{ item }">
                <div class="cell-width">
                    <span v-text="item.user"/>
                </div>
            </template>
            <template v-slot:item.updated_at="{ item }">
                {{ item.updated_at }}
            </template>
            <template v-slot:item.delivery_date="{ item }">
                {{ item.delivery_date }}
            </template>
            <template v-slot:item.comments="{ item }">
                <b v-text="item.comments"></b>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="isAdmin" class="my-1" fab outlined x-small @click.stop="deleteItem(item)">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-task-dialog @success="loadTasks" ref="creator" />

        <kimsa-confirm
            title="Eliminar Tarea"
            content="¿Esta seguro que desea eliminar esta Tarea?"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import CreateTaskDialog from "@/components/task/CreateTaskDialog";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";

export default {
    name: "Tasks",
    components: {KimsaSelect, CreateTaskDialog, KimsaConfirm, KimsaTextField},
    data() {
        return {
            loading: false,
            tasks: [],
            clientOptions: [],
            userOptions: [],
            statusOptions: [],
            priorityOptions: [],
            search: '',
            currentId: null,
            loadingDelete: false,
            filterClient: null,
            filterUser: null,
            filterStatus: null,
            filterPriority: null,
            useFilter: false,
            waitingFilter: false,
            templateHeaders: [
                { text: 'Status', value: 'status', class: 'grey white--text'},
                { text: 'Título', value: 'name', class: 'grey white--text'},
                { text: 'Cliente', value: 'client', class: 'grey white--text'},
                { text: 'Responsable', value: 'user', class: 'grey white--text'},
                { text: 'Última Actualización', value: 'updated_at', class: 'grey white--text'},
                { text: 'Fecha de Entrega', value: 'delivery_date', class: 'grey white--text'},
                { text: 'Días', value: 'days', class: 'grey white--text'},
                { text: 'Comentarios', value: 'comments', class: 'grey white--text'},
                { text: '', value: 'actions', sortable: false, class: 'grey'},
            ],
            headers: [
                { text: 'Status', value: 'status', class: 'grey white--text'},
                { text: 'Título', value: 'name', class: 'grey white--text'},
                { text: 'Cliente', value: 'client', class: 'grey white--text'},
                { text: 'Responsable', value: 'user', class: 'grey white--text'},
                { text: 'Última Actualización', value: 'updated_at', class: 'grey white--text'},
                { text: 'Fecha de Entrega', value: 'delivery_date', class: 'grey white--text'},
                { text: 'Días', value: 'days', class: 'grey white--text'},
                { text: 'Comentarios', value: 'comments', class: 'grey white--text'},
                { text: '', value: 'actions', sortable: false, class: 'grey'},
            ]
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
        await vm.loadUsers()
        await vm.loadStatus()
        await vm.loadPriorities()
        await vm.loadTasks()
    },
    methods: {
        async loadTasks(payload = {}) {
            const vm = this
            vm.loading = true

            await Axios.post('manager-tasks', payload).then(res => {
                console.log('res loadTasks',res)
                vm.tasks = res.data.result.managerTasks.map(task => {
                    return {
                        id: task.id,
                        status: task.status.color,
                        name: task.name || 'n/d',
                        client: task.client?.name || 'n/d',
                        user: task.user?.name || 'n/d',
                        updated_at: vm.$moment(task.updated_at).format('DD/MM/YYYY'),
                        delivery_date: vm.$moment(task.delivery_date).format('DD/MM/YYYY'),
                        days: vm.$moment().diff(vm.$moment(task.created_at), 'days'),
                        comments: vm.loadQuantity(task.comments)
                    }
                })
            }).catch(er => {
                console.log('error loadTasks', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        loadQuantity(array) {
            const vm = this
            let quantity = array && array.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            vm.$refs.creator.loadTask(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`manager-task/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Tarea eliminada')
            }).catch(er => {
                console.log('er',er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadTasks()
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clientOptions = res.data.result.clients
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadUsers() {
            const vm = this
            await Axios.get(`client/${vm.filterClient}/users`).then(res => {
                // console.log('res loadUsers', res)
                vm.userOptions = res.data.result.users
            }).catch(er => {
                console.log('error loadUsers', er)
            })
        },
        async loadStatus() {
            const vm = this
            await Axios.get(`status`).then(res => {
                // console.log('res loadStatus',res)
                vm.statusOptions = res.data.result.status
            }).catch(er => {
                console.log('error loadStatus', er)
            })
        },
        async loadPriorities() {
            const vm = this
            await Axios.get(`priorities`).then(res => {
                // console.log('res loadPriorities', res)
                vm.priorityOptions = res.data.result.priorities
            }).catch(er => {
                console.log('error loadPriorities', er)
            })
        },
        clearFilters() {
            const vm = this
            vm.filterClient = null
            vm.filterUser = null
            vm.filterStatus = null
            vm.filterPriority = null
            vm.headers = [...vm.templateHeaders]
            vm.loadTasks()
        },
        filterTasks() {
            const vm = this

            let headers = [...vm.templateHeaders]
            let payload = {}
            if(vm.filterClient) {
                payload.client_id = vm.filterClient
                headers = headers.filter(h => h.value !== 'client')
            }
            if(vm.filterUser) {
                payload.user_id = vm.filterUser
                headers = headers.filter(h => h.value !== 'user')
            }
            if(vm.filterStatus) payload.status_id = vm.filterStatus
            if(vm.filterPriority) payload.priority_id = vm.filterPriority
            vm.headers = headers

            console.log('paylaod',payload)
            vm.loadTasks(payload)
        },
        customSort(items, index, isDesc) {
            const vm = this
            let isDate = index[0] === 'updated_at' || index[0] === 'delivery_date'
            items.sort((a, b) => {
                return !isDesc[0] ?
                    vm.compare(a[index[0]]?.toLowerCase(), b[index[0]]?.toLowerCase(), isDate) :
                    vm.compare(b[index[0]]?.toLowerCase(), a[index[0]]?.toLowerCase(), isDate)
            });
            return items;
        },
        compare(a,b, isDate) {
            const vm = this
            if(isDate) {
                return vm.$moment(a, 'DD-MM-YYYY').valueOf() - vm.$moment(b, 'DD-MM-YYYY').valueOf()
            } else {
                if(a < b) { return -1; }
                if(a > b) { return 1; }
            }
        },
        setFilterClient(client) {
            const vm = this
            console.log('setFilterClient', client)
            vm.filterClient = client
        },
        setFilterUser(user) {
            const vm = this
            console.log('setFilterUser', user)
            vm.filterUser = user
        },
    },
    watch: {
        filterClient() {
            this.loadUsers()
        },
    }
}
</script>

<style scoped>

.status-text {
    display: inline-block;
    font-size: 11px;
    margin-right: 5px;
}

.title-section {
    color: var(--v-primary-base);
}

.cell-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    max-height: 1.5em;
}

</style>